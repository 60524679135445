import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import * as wordList from 'src/assets/word-list.json';
import * as answerList from 'src/assets/answers.json';
import { ToastrService } from "ngx-toastr";

@Injectable()
export class GameStateService {
  constructor(private toast: ToastrService) {
    localStorage.setItem('numberOfKeysPressed', "0");
    localStorage.setItem('guessesCompleted', "0");
    localStorage.setItem('guesses', JSON.stringify([]));
  }

  public allowedKeyString: string = 'abcdefghijklmnopqrstuvwxyz';
  public allowedKeys: string[] = this.allowedKeyString.split('');

  public allowedWords: string[] = wordList.allowedWords;
  public potentialAnswers: string[] = answerList.answers;

  public keysPressedSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public numberOfKeysPressed: number = 0;
  public keysPressed: string[] = [];
  public guessesCompleted: number = 0;
  public guesses: string[][] = [];

  recordKeyPress(key: string) {
    key = key.toLowerCase();
    if (this.allowedKeys.includes(key)) {
      this.keysPressed.push(key);
      this.numberOfKeysPressed++;
      localStorage.setItem('numberOfKeysPressed', this.numberOfKeysPressed.toString());
    } else if (key === 'backspace') {
      //TODO: check here if going beyond the current guess
    } else if (key === 'enter') {
      this.toast.success("Checking guess...", "Alldle");
    }

    this.keysPressedSubject.next(key);
  }

  decrementNumberOfKeysPressed() {
    this.numberOfKeysPressed--;
    if(this.numberOfKeysPressed < 0) {
      this.numberOfKeysPressed = 0;
    }
  }

  incrementGuessesCompleted(guess: string[]) {
    this.guessesCompleted++;
    localStorage.setItem('guessesCompleted', this.guessesCompleted.toString());
    this.guesses.push(guess);
    localStorage.setItem('guesses', JSON.stringify(this.guesses));
  }

  handleEnterPress(key: string) {
    // key = key.toUpperCase();
    // if (key === 'ENTER') {
    //   this.checkAnswer();
    // }
  }

  handleBackspacePress() {
    this.keysPressed.pop();
    this.decrementNumberOfKeysPressed();
    localStorage.setItem('numberOfKeysPressed', this.numberOfKeysPressed.toString());
  }

  reset() {
    this.keysPressed = [];
    this.numberOfKeysPressed = 0;
  }
}