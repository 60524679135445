import { Component, Input, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { ClearGuessService } from 'src/app/services/clear-guess.service';
import { GameStateService } from 'src/app/services/game-state.service';

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss']
})
export class KeyboardComponent implements OnInit {
  firstRow: string[] = ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'];
  secondRow: string[] = ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'];
  thirdRow: string[] = ['Z', 'X', 'C', 'V', 'B', 'N', 'M'];
  fourthRow: string[] = ['BACKSPACE', 'ENTER'];

  firstRowStatus: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  secondRowStatus: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
  thirdRowStatus: number[] = [0, 0, 0, 0, 0, 0, 0];

  letterStatusChangedSub: Subscription;
  clearGuessSub: Subscription;

  constructor(private clearGuessService: ClearGuessService,
    private gameStateService: GameStateService) {
    this.letterStatusChangedSub = this.clearGuessService.letterStatusChangedSubject.subscribe(s => {
      const letter = s.letter.toUpperCase();
      // TODO: never lower status and only set it higher
      if (this.firstRow.includes(letter)) {
        this.firstRowStatus[this.firstRow.indexOf(letter)] = s.status;
      } else if (this.secondRow.includes(letter)) {
        this.secondRowStatus[this.secondRow.indexOf(letter)] = s.status;
      } else if (this.thirdRow.includes(letter)) {
        this.thirdRowStatus[this.thirdRow.indexOf(letter)] = s.status;
      }
    });

    this.clearGuessSub = this.clearGuessService.clearGuessesSubject.subscribe((clear: boolean) => {
      if (clear) {
        this.clearGuess();
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.letterStatusChangedSub.unsubscribe();
    this.clearGuessSub.unsubscribe();
  }

  clearGuess() {
    this.firstRowStatus = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.secondRowStatus = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.thirdRowStatus = [0, 0, 0, 0, 0, 0, 0];
  }

  pressKey(key: string) {
    this.gameStateService.recordKeyPress(key);
  }

}
