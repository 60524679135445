<div class="guess-border">
  <div class="container">
    <span class="letter-card" [@flipState]="guess1Flip[0]" [ngClass]="{'normal-letter': guess1Status[0] === 0, 'correct-letter': guess1Status[0] === 1, 'letter-in-wrong-place': guess1Status[0] === 2, 'wrong-letter-subtle': guess1Status[0] === 3}">
      <span [@flipState2]="guess1Flip2[0]">{{ guess1[0] }}</span>
    </span>
    <span class="letter-card" [@flipState]="guess1Flip[1]" [ngClass]="{'normal-letter': guess1Status[1] === 0, 'correct-letter': guess1Status[1] === 1, 'letter-in-wrong-place': guess1Status[1] === 2, 'wrong-letter-subtle': guess1Status[1] === 3}">
      <span [@flipState2]="guess1Flip2[1]">{{ guess1[1] }}</span>
    </span>
    <span class="letter-card" [@flipState]="guess1Flip[2]" [ngClass]="{'normal-letter': guess1Status[2] === 0, 'correct-letter': guess1Status[2] === 1, 'letter-in-wrong-place': guess1Status[2] === 2, 'wrong-letter-subtle': guess1Status[2] === 3}">
      <span [@flipState2]="guess1Flip2[2]">{{ guess1[2] }}</span>
    </span>
    <span class="letter-card" [@flipState]="guess1Flip[3]" [ngClass]="{'normal-letter': guess1Status[3] === 0, 'correct-letter': guess1Status[3] === 1, 'letter-in-wrong-place': guess1Status[3] === 2, 'wrong-letter-subtle': guess1Status[3] === 3}">
      <span [@flipState2]="guess1Flip2[3]">{{ guess1[3] }}</span>
    </span>
    <span class="letter-card" [@flipState]="guess1Flip[4]" [ngClass]="{'normal-letter': guess1Status[4] === 0, 'correct-letter': guess1Status[4] === 1, 'letter-in-wrong-place': guess1Status[4] === 2, 'wrong-letter-subtle': guess1Status[4] === 3}">
      <span [@flipState2]="guess1Flip2[4]">{{ guess1[4] }}</span>
    </span>
    <button class="x-button" (click)="clearMyGuess()" [disabled]="true">X</button>
  </div>
</div>