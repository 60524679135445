import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { GuessDirective } from 'src/app/directives/guess.directive';
import { ClearGuessService } from 'src/app/services/clear-guess.service';
import { UserGuessComponent } from '../user-guess/user-guess.component';
import { fromEvent, Subscription } from 'rxjs';
import * as answerList from '../../../assets/answers.json';
import { GameStateService } from 'src/app/services/game-state.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  potentialAnswers: string[] = answerList.answers;
  answer: string;
  answerIndex: number;
  guessesAdded: number = 0;
  keysSub: Subscription;
  enterKeyPressSub: Subscription;
  // clearSingleGuessSub: Subscription;
  // numberOfKeysPressed: number = 0;

  allowedKeyString: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  allowedKeys: string[] = this.allowedKeyString.split('');
  showGameIndexInput: boolean = false;

  @ViewChild(GuessDirective, { static: true }) guessHost!: GuessDirective;

  constructor(private clearGuessService: ClearGuessService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private gameStateService: GameStateService) {
    this.answerIndex = Math.floor(Math.random() * this.potentialAnswers.length);
    this.answer = this.potentialAnswers[this.answerIndex];
    console.log(`Answer index: ${this.answerIndex}`);
    // console.log(`Answer: ${this.answer}`);

    this.enterKeyPressSub = fromEvent(document, 'keypress').subscribe(k => {
      const keyEvent = k as KeyboardEvent;
      let key = keyEvent.key;
      key = key.toUpperCase();
      if (key === 'ENTER') {
        k.preventDefault();
      }
    });

    this.keysSub = fromEvent(document, 'keyup').subscribe(k => {
      const keyEvent = k as KeyboardEvent;
      let key = keyEvent.key;
      key = key.toLowerCase();
      // if (this.allowedKeys.includes(key)) {
      //   this.numberOfKeysPressed++;
      // } else if (key === 'BACKSPACE') {
      //   this.numberOfKeysPressed--;
      // } else if (key === 'ENTER') {
      //   this.toast.success("Checking guess...", "Alldle");
      //   k.preventDefault();
      // }
      this.gameStateService.recordKeyPress(key);
    });

    // this.clearSingleGuessSub = this.clearGuessService.clearSingleGuessSubject.subscribe(params => {
    //   this.numberOfKeysPressed-=5;
    // });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.keysSub.unsubscribe();
    this.enterKeyPressSub.unsubscribe();
    // this.clearSingleGuessSub.unsubscribe();
  }

  clickGameIndexInput(): void {
    this.showGameIndexInput = true;
  }

  setGameIndex(gameIndex: string): void {
    console.log(`SetGameIndex:`, gameIndex);
    this.showGameIndexInput = false;
    this.clearGuesses(parseInt(gameIndex));
  }

  // TODO: sign in for users
  // track player games, number of wins, how many guess it took to win
  // previous games and guesses

  // TODO: require guesses to be an actual word
  // call free dictionary API to confirm
  // if can't get it in number of guesses, allow them to add more guesses
  // button to reveal answer, if they choose to give up

  // TODO: add a clear button on this component and send message out
  // user guess components can subscribe to this message and clear their guesses
  clearGuesses(answerIndex?: number) {
    this.clearGuessService.clearGuesses();
    this.answerIndex = answerIndex || Math.floor(Math.random() * this.potentialAnswers.length);
    this.answer = this.potentialAnswers[this.answerIndex];
    this.clearGuessService.setAnswer(this.answer);

    const viewContainerRef = this.guessHost.viewContainerRef;
    viewContainerRef.clear();

    this.guessesAdded = 0;
    // TODO: after clearing the guesses, must click on the page or it will not work
  }

  addGuess() {
    const previousGuessMin = 20;
    const previousGuessMax = 26;
    this.guessesAdded++;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UserGuessComponent);
    const viewContainerRef = this.guessHost.viewContainerRef;
    if (this.guessesAdded === 0) {
      viewContainerRef.clear();
    }
    const componentRef = viewContainerRef.createComponent<UserGuessComponent>(componentFactory);
    componentRef.instance.answer = this.answer;
    componentRef.instance.guessMin = (previousGuessMin + (5 * this.guessesAdded)).toString();
    componentRef.instance.guessMax = (previousGuessMax + (5 * this.guessesAdded)).toString();

    // BUG: clicking on the whitespace on the document,
    // allows the new component to work correctly
    // why is this needed?
  }

  revealAnswer() {
    console.log(`Answer: ${this.answer}`);
  }
}
