<div class="container">
  <div class="button-margin">
    <button (click)="clearGuesses()">Clear</button>
    <button (click)="addGuess()">Add Guess</button>
    <button (click)="revealAnswer()">Reveal Answer</button>
    <span *ngIf="showGameIndexInput"><input #gameIndexInput type="text" class="maxInputWidth"><button (click)="setGameIndex(gameIndexInput.value)">Set</button></span>
    <span *ngIf="!showGameIndexInput" class="index-margin" (click)="clickGameIndexInput()">Game Index: {{ answerIndex }}</span>
  </div>
  <app-user-guess answer={{answer}}></app-user-guess>
  <app-user-guess answer={{answer}} guessMin="5" guessMax="11"></app-user-guess>
  <app-user-guess answer={{answer}} guessMin="10" guessMax="16"></app-user-guess>
  <app-user-guess answer={{answer}} guessMin="15" guessMax="21"></app-user-guess>
  <app-user-guess answer={{answer}} guessMin="20" guessMax="26"></app-user-guess>
  <ng-template guessHost></ng-template>
  <app-keyboard></app-keyboard>
</div>