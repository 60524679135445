<div class="container-column">
  <div class="container">
    <div *ngFor="let item of firstRow; index as i;" (click)="pressKey(item)">
      <span class="keyboard-card normal-letter" [ngClass]="{'normal-letter': firstRowStatus[i] === 0, 'correct-letter': firstRowStatus[i] === 1, 'letter-in-wrong-place': firstRowStatus[i] === 2, 'wrong-letter-subtle': firstRowStatus[i] === 3}">{{item}}</span>
    </div>
  </div>
  <div class="container margin-second-row">
    <div *ngFor="let item of secondRow; index as i;" (click)="pressKey(item)">
      <span class="keyboard-card normal-letter" [ngClass]="{'normal-letter': secondRowStatus[i] === 0, 'correct-letter': secondRowStatus[i] === 1, 'letter-in-wrong-place': secondRowStatus[i] === 2, 'wrong-letter-subtle': secondRowStatus[i] === 3}">{{item}}</span>
    </div>
  </div>
  <div class="container margin-third-row">
    <div *ngFor="let item of thirdRow; index as i;" (click)="pressKey(item)">
      <span class="keyboard-card normal-letter" [ngClass]="{'normal-letter': thirdRowStatus[i] === 0, 'correct-letter': thirdRowStatus[i] === 1, 'letter-in-wrong-place': thirdRowStatus[i] === 2, 'wrong-letter-subtle': thirdRowStatus[i] === 3}">{{item}}</span>
    </div>
  </div>
  <div class="container margin-fourth-row">
    <div *ngFor="let item of fourthRow; index as i;" (click)="pressKey(item)">
      <span class="keyboard-card letter-card-big normal-letter">{{item}}</span>
    </div>
  </div>
</div>