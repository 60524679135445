import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

// TODO: rename this to GuessService
@Injectable()
export class ClearGuessService {
  public clearGuessesSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public answerSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public letterStatusChangedSubject: BehaviorSubject<{ letter: string, status: number }> = new BehaviorSubject<{ letter: string, status: number }>({ letter: '', status: 0 });
  public clearSingleGuessSubject: BehaviorSubject<{ min: number, max: number }> = new BehaviorSubject<{ min: number, max: number }>({ min: 0, max: 0 });

  constructor() { }

  clearGuesses() {
    this.clearGuessesSubject.next(true);
  }

  clearSingleGuess(min: number, max: number) {
    this.clearSingleGuessSubject.next({ min, max });
  }

  setAnswer(answer: string) {
    this.answerSubject.next(answer);

    // TODO: when setting a new answer, may need to set a loading state
  }

  letterStatusChanged(letter: string, status: number) {
    this.letterStatusChangedSubject.next({ letter: letter, status: status });
  }
}