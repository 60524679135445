import { Component, Input, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ClearGuessService } from 'src/app/services/clear-guess.service';
import * as wordList from '../../../assets/word-list.json';
import * as answerList from '../../../assets/answers.json';
import { ToastrService } from 'ngx-toastr';
import { GameStateService } from 'src/app/services/game-state.service';

@Component({
  selector: 'app-user-guess',
  templateUrl: './user-guess.component.html',
  styleUrls: ['./user-guess.component.scss'],
  animations: [
    trigger('flipState', [
      state('active', style({
        transform: 'rotateY(179deg)'
      })),
      state('inactive', style({
        transform: 'rotateY(0)'
      })),
      transition('active => inactive', animate('500ms ease-out')),
      transition('inactive => active', animate('500ms ease-in'))
    ]),
    trigger('flipState2', [
      state('inactive', style({
        transform: 'rotateY(179deg)'
      })),
      state('active', style({
        transform: 'rotateY(0)'
      })),
      transition('inactive => active', animate('500ms ease-out')),
      transition('active => inactive', animate('500ms ease-in'))
    ])
  ]
})
export class UserGuessComponent implements OnInit {
  @Input()
  answer: string = '';

  @Input()
  guessMin: string = '0';

  @Input()
  guessMax: string = '6';

  answerLetters: string[] = [];

  // keysSub: Subscription;
  clearGuessSub: Subscription;
  answerSub: Subscription;
  clearSingleGuessSub: Subscription;
  // keysPressed: string[] = [];
  guess1: string[] = [];
  guess1Status: number[] = [0, 0, 0, 0, 0];
  guess1Flip: string[] = ['inactive', 'inactive', 'inactive', 'inactive', 'inactive'];
  guess1Flip2: string[] = ['active', 'active', 'active', 'active', 'active'];

  allowedKeyString: string = 'abcdefghijklmnopqrstuvwxyz';
  allowedKeys: string[] = this.allowedKeyString.split('');

  allowedWords: string[] = wordList.allowedWords;
  potentialAnswers: string[] = answerList.answers;

  clearMyGuessAllowed: boolean = false;
  guessLocked: boolean = false;

  constructor(private clearGuessService: ClearGuessService,
    private toast: ToastrService,
    private gameStateService: GameStateService) {
    // TODO: move this to the home component
    // keysPressed should be tracked by the home component
    // this.keysSub = fromEvent(document, 'keyup').subscribe(k => {
    //   const keyEvent = k as KeyboardEvent;
    //   const key = keyEvent.key;
    //   this.recordKeyPress(key);
    //   this.handleEnterPress(key);
    //   this.handleBackspacePress(key);
    // });
    this.gameStateService.keysPressedSubject.subscribe(key => {
      this.recordKeyPress(key);
      this.handleEnterPress(key);
      this.handleBackspacePress(key);
    });

    this.clearGuessSub = this.clearGuessService.clearGuessesSubject.subscribe((clear: boolean) => {
      if (clear) {
        this.clearGuess();
      }
    });

    this.answerSub = this.clearGuessService.answerSubject.subscribe((answer: string) => {
      this.answer = answer;
      this.answerLetters = this.answer.split('');
    });

    this.clearSingleGuessSub = this.clearGuessService.clearSingleGuessSubject.subscribe(params => {
      // TODO: this will need fixed
      // if (this.keysPressed.length <= 5) {
      //   this.keysPressed = [];
      // } else {
      //   this.keysPressed = this.keysPressed.slice(0, this.keysPressed.length - 5);
      // }
      // if (this.keysPressedParam > 0) {
      //   this.keysPressedParam-=5;
      // }
    });
  }

  ngOnInit(): void {
    this.answerLetters = this.answer.split('');
    console.log(`guessMin: ${this.guessMin}`);
    console.log(`guessMax: ${this.guessMax}`);
    // console.log(`Answer Letters: ${this.answerLetters}`);
  }

  ngOnDestroy() {
    // this.keysSub.unsubscribe();
    this.clearGuessSub.unsubscribe();
    this.answerSub.unsubscribe();
    this.clearSingleGuessSub.unsubscribe();
  }

  handleEnterPress(key: string) {
    key = key.toUpperCase();
    if (key === 'ENTER') {
      this.checkAnswer();
    }
  }

  handleBackspacePress(key: string) {
    // TODO: if a guess is "locked" and the answer checked, backspace should not go any further back
    // console.log('user-guess.component handleBackspacePress');
    key = key.toUpperCase();
    if (key === 'BACKSPACE' && !this.guessLocked) {
      const numberOfKeysPressed = this.gameStateService.numberOfKeysPressed;
      // console.log('if BACKSPACE numberOfKeysPressed', numberOfKeysPressed);
      const guessMin = parseInt(this.guessMin);
      const guessMax = parseInt(this.guessMax);
      // console.log('if BACKSPACE guessMin guessMax', guessMin, guessMax);
      //const numberOfKeysPressedBeforeBackspace = numberOfKeysPressed + 1;
      if (numberOfKeysPressed > guessMin && numberOfKeysPressed < guessMax) {
        // console.log('trying to pop from guess');
        this.guess1.pop();
        this.gameStateService.handleBackspacePress();
      }
    }
  }

  recordKeyPress(key: string): void {
    key = key.toLowerCase();
    if (!this.allowedKeys.includes(key)) { return; }

    const numberOfKeysPressed = this.gameStateService.numberOfKeysPressed;
    const guessMin = parseInt(this.guessMin);
    const guessMax = parseInt(this.guessMax);
    if (numberOfKeysPressed > guessMin && numberOfKeysPressed < guessMax) {
      this.guess1.push(key);
    }
  }

  clearMyGuess() {
    const guessMin = parseInt(this.guessMin);
    const guessMax = parseInt(this.guessMax);
    // TODO: pass in this.guess1.length
    // should only remove this amount from keysPressed
    this.clearGuessService.clearSingleGuess(guessMin, guessMax);
    this.guess1 = [];
    this.guess1Status = [0, 0, 0, 0, 0];
    this.guess1Flip = ['inactive', 'inactive', 'inactive', 'inactive', 'inactive'];
    this.guess1Flip2 = ['active', 'active', 'active', 'active', 'active'];
  }

  clearGuess() {
    this.guess1 = [];
    this.guess1Status = [0, 0, 0, 0, 0];
    // this.keysPressed = [];
    this.gameStateService.reset();
    this.guess1Flip = ['inactive', 'inactive', 'inactive', 'inactive', 'inactive'];
    this.guess1Flip2 = ['active', 'active', 'active', 'active', 'active'];
    this.clearMyGuessAllowed = false;

    // TODO: move this to the home component
    // this.answer = this.potentialAnswers[Math.floor(Math.random() * this.potentialAnswers.length)];
    // this.answerLetters = this.answer.split('');
  }

  checkAnswer() {
    if(this.guessLocked) { return; }

    if (this.guess1.length === 0) { return; }

    const guess = this.guess1.join('');
    if (!this.allowedWords.includes(guess) && !this.potentialAnswers.includes(guess)) {
      this.toast.warning("Entered guess is not a word", "Alldle");
      return;
    }

    this.guessLocked = true;
    console.log("incrementing guesses completed", this.guessMin, this.guessMax);
    this.gameStateService.incrementGuessesCompleted(this.guess1);
    this.clearMyGuessAllowed = true;

    let timeoutLength = 800;
    // TODO: if already checked the letter then do nothing
    // ex: first iteration checks 'H' then interation 3 checks 'H' again
    for(let i = 0; i < this.guess1.length; i++) {

      setTimeout(() => {
        if (this.guess1[i] === this.answerLetters[i]) {
          this.guess1Status[i] = 1;
        } else if (this.answerLetters.includes(this.guess1[i])) {
          this.guess1Status[i] = 2;
        } else {
          this.guess1Status[i] = 3;
        }
        if (this.guess1Status[i] !== 0) {
          this.clearGuessService.letterStatusChanged(this.guess1[i], this.guess1Status[i]);
        }
      }, timeoutLength);

      setTimeout(() => {
        this.guess1Flip2[i] = 'inactive';
      }, timeoutLength - 400);

      setTimeout(() => {
        this.guess1Flip[i] = 'active';
      }, timeoutLength - 300);
      timeoutLength += 500;
    }
  }
}
